<template>
  <div>
    <div>
      <div>
        <template>
          <div>
            <a-row>
              <a-col :lg="{ span: 6 }" :xxl="{ span: 4 }">
                <el-card :style="{ height: screenHeight + 'px' }">
                  <div style="height: 0.8rem">
                    机构管理
                    <span style="margin-left: 1rem">
                      <a-radio-group
                        v-model="onlyShowGuangDong"
                        :style="{ marginBottom: '8px' }"
                      >
                        <a-radio-button value="all"> 全国 </a-radio-button>
                        <a-radio-button value="gz"> 广东省 </a-radio-button>
                      </a-radio-group>
                    </span>
                  </div>
                  <br />
                  <div style="margin-bottom: 12px"></div>
                  <template>
                    <div>
                      <a-input-search
                        style="margin-bottom: 8px"
                        placeholder="搜索机构"
                        @change="onChange"
                      ></a-input-search>
                      <a-tree
                        :replaceFields="tree_replaceFields"
                        v-if="tree_change"
                        :style="{
                          height: screenHeight - 60 + 'px',
                          overflow: 'auto',
                        }"
                        class="scroll_bar"
                        @expand="onExpand"
                        @select="onSelect"
                        show-icon
                        :expanded-keys="expandedKeys"
                        :auto-expand-parent="autoExpandParent"
                        :tree-data="areaClassifyData"
                      >
                        <a-icon slot="switcherIcon" type="down" />
                        <a-icon
                          slot="province"
                          type="home"
                          theme="twoTone"
                          two-tone-color="#eb2f96"
                        />
                        <a-icon
                          slot="city"
                          type="home"
                          theme="twoTone"
                          two-tone-color="#FFA02B"
                        />
                        <a-icon
                          slot="area"
                          type="home"
                          theme="twoTone"
                          two-tone-color="#5AA8FF"
                        />
                        <a-icon
                          slot="organization"
                          type="smile"
                          theme="twoTone"
                          two-tone-color="#52c41a"
                        />
                        <template slot="title" slot-scope="{ name }">
                          <span v-if="name.indexOf(searchValue) > -1">
                            {{ name.substr(0, name.indexOf(searchValue)) }}
                            <span style="color: #f50">{{ searchValue }}</span>
                            {{
                              name.substr(
                                name.indexOf(searchValue) + searchValue.length
                              )
                            }}
                          </span>
                          <span v-else>{{ name }}</span>
                        </template>
                      </a-tree>
                    </div>
                  </template>
                </el-card>
              </a-col>
              <a-col :lg="{ span: 18 }" :xxl="{ span: 20 }">
                <el-card :style="{ 'min-height': screenHeight + 'px' }">
                  <div style="height: 0.8rem">机构</div>
                  <br />
                  <!-- 按钮 -->
                  <el-button
                    size="small"
                    type="primary"
                    @click="addOrganization()"
                    >新增机构</el-button
                  >
                  &nbsp;
                  <el-button size="small" @click="showDrawer"
                    >按条件搜索</el-button
                  >
                  <br />
                  <div style="margin-bottom: 12px"></div>
                  <!-- 表单 -->
                  <template>
                    <div
                      :style="{
                        height: screenHeight - 120 + 'px',
                        overflow: 'auto',
                      }"
                      class="scroll_bar"
                    >
                      <!--:replaceFields="replaceFields"-->
                      <el-table
        header-row-class-name="my-table-head-style"
                        style="margin-top: 0; margin-bottom: 0"
                        v-loading="spinning"
                        :data="data"
                        row-key="id"
                        border
                        :tree-props="{
                          children: 'children',
                          hasChildren: 'hasChildren',
                        }"
                      >
                        <el-table-column prop="name" label="机构名称">
                        </el-table-column>
                        <el-table-column prop="contact" label="联系方式">
                        </el-table-column>
                        <el-table-column prop="address" label="机构地址">
                        </el-table-column>
                        <el-table-column prop="introduction" label="机构信息">
                        </el-table-column>
                        <el-table-column
                          width="270"
                          label="操作"
                          align="center"
                        >
                          <template slot-scope="scope">
                            <a-tooltip placement="topLeft" title="新增子级机构">
                              <el-button
                                type="success"
                                icon="el-icon-circle-plus-outline"
                                size="mini"
                                @click="addOrganizationChild(scope.row)"
                                >新增
                              </el-button>
                            </a-tooltip>
                            <el-button
                              type="primary"
                              icon="el-icon-edit-outline"
                              size="mini"
                              @click="editOrganization(scope.row)"
                              >编辑
                            </el-button>
                            <el-button
                              type="danger"
                              icon="el-icon-delete"
                              size="mini"
                              @click="deleteOrganization(scope.row)"
                            >
                              删除
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </template>
                </el-card>
              </a-col>
            </a-row>
          </div>
        </template>
      </div>
    </div>
    <!-- 添加供应商 -->
    <template>
      <div>
        <a-modal
          v-model="addOrganizationVisible"
          title="新增机构"
          :width="organizationFormValue.isTopOrganization ? 800 : 500 + 'px'"
        >
          <template slot="footer">
            <a-button @click="addOrganizationVisible = false">取消</a-button>
            <a-button type="primary" @click="toAddOrganization">
              确认
            </a-button>
          </template>
          <a-form-model
            :model="organizationFormValue"
            ref="organizationRefFormAdd"
            :rules="organizationRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 15 }"
          >
            <a-row>
              <a-col :span="organizationFormValue.isTopOrganization ? 12 : 24">
                <a-form-model-item label="机构名称" prop="name">
                  <a-input v-model="organizationFormValue.name"  placeholder="请输入机构名称" />
                </a-form-model-item>
                <a-form-model-item
                  label="机构位置"
                  prop="address"
                  v-if="organizationFormValue.isTopOrganization"
                >
                  <a-input v-model="organizationFormValue.address" placeholder="请输入机构位置"/>
                </a-form-model-item>
                <a-form-model-item
                  label="所属区域"
                  prop="areaIds"
                  v-if="organizationFormValue.isTopOrganization"
                >
                  <template>
                    <a-cascader
                      :options="areaData"
                      :fieldNames="fieldName"
                      :show-search="{ areaFilter }"
                      @change="areaChange"
                      placeholder="请选择"
                      v-model="organizationFormValue.areaIds"
                      change-on-select
                    >
                    </a-cascader>
                  </template>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="联系方式"
                  prop="contact"
                  v-if="organizationFormValue.isTopOrganization"
                >
                  <a-input v-model="organizationFormValue.contact" placeholder="请输入联系方式"/>
                </a-form-model-item>
                <a-form-model-item
                  label="机构简介"
                  prop="introduction"
                  v-if="organizationFormValue.isTopOrganization"
                >
                  <a-textarea v-model="organizationFormValue.introduction" placeholder="请输入...."/>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-modal>
      </div>
    </template>
    <!-- 编辑机构 -->
    <template>
      <div>
        <a-modal
          v-model="editOrganizationVisible"
          title="编辑机构"
          :width="organizationFormValue.isTopOrganization ? 800 : 500 + 'px'"
        >
          <template slot="footer">
            <a-button @click="editOrganizationVisible = false">取消</a-button>
            <a-button type="primary" @click="toEditOrganization">
              确认
            </a-button>
          </template>
          <a-form-model
            :model="organizationFormValue"
            ref="organizationRefFormEdit"
            :rules="organizationRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 15 }"
          >
            <a-row>
              <a-col :span="organizationFormValue.isTopOrganization ? 12 : 24">
                <a-form-model-item label="机构名称" prop="name">
                  <a-input v-model="organizationFormValue.name" placeholder="请输入机构名称" />
                </a-form-model-item>
                <a-form-model-item
                  label="机构位置"
                  prop="address"
                  v-if="organizationFormValue.isTopOrganization"
                >
                  <a-input v-model="organizationFormValue.address" placeholder="请输入机构位置"/>
                </a-form-model-item>
                <a-form-model-item
                  label="所属区域"
                  prop="areaIds"
                  v-if="organizationFormValue.isTopOrganization"
                >
                  <template>
                    <a-cascader
                      :options="areaData"
                      :fieldNames="fieldName"
                      :show-search="{ areaFilter }"
                      @change="areaChange"
                      placeholder="请选择"
                      v-model="organizationFormValue.areaIds"
                      change-on-select
                    >
                    </a-cascader>
                  </template>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item
                  label="联系方式"
                  prop="contact"
                  v-if="organizationFormValue.isTopOrganization"
                >
                  <a-input v-model="organizationFormValue.contact" placeholder="请输入联系方式"/>
                </a-form-model-item>
                <a-form-model-item
                  label="机构简介"
                  prop="introduction"
                  v-if="organizationFormValue.isTopOrganization"
                >
                  <a-textarea v-model="organizationFormValue.introduction" placeholder="请输入...."/>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-modal>
      </div>
    </template>

    <template>
      <div>
        <a-drawer
          title="搜索机构"
          width="520"
          placement="right"
          :closable="false"
          :visible="drawerVisible"
          :after-visible-change="afterVisibleChange"
          @close="onDrawerClose"
        >
          <div>
            <a-form-model
              :model="query"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-row>
                <a-col :span="12">
                  <a-form-model-item label="机构名称" prop="name">
                    <a-input v-model="query.name" allowClear placeholder="请输入机构名称"/>
                  </a-form-model-item>
                  <a-form-model-item label="机构地址" prop="formerName">
                    <a-input v-model="query.address" allowClear placeholder="请输入机构地址"/>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="联系方式" prop="linkMan">
                    <a-input v-model="query.contact" allowClear placeholder="请输入联系方式"/>
                  </a-form-model-item>
                  <a-form-model-item label="机构描述" prop="phone">
                    <a-input v-model="query.introduction" allowClear placeholder="请输入机构描述" />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button @click="removeAllQuery">清空</a-button>&nbsp;
            <a-button type="primary" @click="searchQuery">搜索</a-button>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      replaceFields: { children: "children", title: "name", key: "code" },
      tree_replaceFields: { children: "children", title: "name", key: "code" },
      tree_change: true,
      areaData: [],
      onlyShowGuangDong: "all",
      fieldName: { label: "name", value: "code", children: "children" },
      organizationFormValue: {
        id: "",
        name: "",
        address: "",
        areaIds: [],
        contact: "",
        introduction: "",
        parent: "",
        isTopOrganization: true,
      },
      organizationRules: {
        name: [{ required: true, message: "请输入机构名字!", trigger: "blur" }],
        address: [
          { required: true, message: "请输入机构地址!", trigger: "blur" },
        ],
        areaIds: [
          { required: true, message: "请选择机构区域!", trigger: "blur" },
        ],
      },
      query: {
        name: "",
        address: "",
        contact: "",
        introduction: "",
      },
      spinning: true,
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50, //屏幕高度
      drawerVisible: false,
      titleData: [], //标题所有title的数据
      addOrganizationVisible: false,
      editOrganizationVisible: false,
      expandedKeys: [], //树key
      searchValue: "", //树搜索
      autoExpandParent: true, //树
      searchText: "", //搜索
      searchInput: null, //搜索
      areaClassifyData: [], //分类树数据
      data: [],
      treeSelectedKeys: [],
    };
  },
  watch: {
    screenWidth: function (val) {
      //监听屏幕宽度变化
      console.log(val);
    },
    screenHeight: function (val) {
      //监听屏幕高度变化
      console.log(val);
    },
    onlyShowGuangDong: function (val) {
      this.treeSelectedKeys = [];
      this.expandedKeys = [];
      this.tree_change = false;
      // 在组件移除后，重新渲染组件
      // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
      this.$nextTick(() => {
        this.tree_change = true;
      });
      this.getAreaClassifyData();
      this.spinning = true;
      this.getOrganizationData();
    },
  },
  mounted() {
    const _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },
  created() {
    this.getAreaClassifyData();
    this.getOrganizationData();
    this.getAreaData();
  },
  computed: {
    pageHeight: function () {
      let height = document.documentElement.clientHeight;
      return height - 50;
    },
  },
  methods: {
    showDrawer() {
      this.drawerVisible = true;
    },
    onDrawerClose() {
      this.drawerVisible = false;
    },
    afterVisibleChange() {},
    areaChange(value, selectedOptions) {
      console.log(value, selectedOptions);
    },
    areaFilter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    toAddOrganization() {
      this.$refs.organizationRefFormAdd.validate((valid) => {
        if (valid) {
          this.http.organization.createOrganization(this.organizationFormValue)
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.addOrganizationVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getOrganizationData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteOrganization(key) {
      let self = this;
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.organization.deleteOrganization({id: key.id})
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                self.getOrganizationData();
              } else {
                this.$message.error(response.data.msg);
              }
            });
        },
        onCancel() {},
      });
    },

    //树依赖方法
    //树   展开节点的时候触发
    onExpand(expandedKeys) {
      console.log(expandedKeys);
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    //当点击选择的时候
    onSelect(selectedKeys) {
      console.log(selectedKeys);
      this.treeSelectedKeys = selectedKeys;
      this.getOrganizationData();
    },
    onChange(e) {
      console.log("搜索中");
      const value = e.target.value;
      if (value === "") {
        this.expandedKeys = [];
        this.searchValue = "";
      } else {
        const expandedKeys = this.titleData
          .map((item) => {
            if (item.title.indexOf(value) > -1) {
              return this.getParentKey(item.title, this.areaClassifyData);
            }
            return null;
          })
          .filter((item, i, self) => item && self.indexOf(item) === i);
        Object.assign(this, {
          expandedKeys,
          searchValue: value,
          autoExpandParent: true,
        });
      }
    },
    //树 获取父id 去展开
    getParentKey(title, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          //注意
          if (node.children.some((item) => item.name === title)) {
            //注意
            parentKey = node.code;
          } else if (this.getParentKey(title, node.children)) {
            parentKey = this.getParentKey(title, node.children);
          }
        }
      }
      return parentKey;
    },
    //获取树的所有节点值
    getTitleData(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        //注意
        const title = node.name;
        this.titleData.push({
          title: title,
        });
        if (node.children) {
          this.getTitleData(node.children);
        }
      }
    },
    //树依赖方法结束
    getAreaData() {
      this.http.area
        .getAreaListHasId({
          onlyShowGuangDong: this.onlyShowGuangDong === "all" ? false : true,
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.areaData = res.data.data;
          } else {
            this.$message.warning("数据获取失败");
          }
        });
    },
    getAreaClassifyData() {
      let params = {
        onlyShowGuangDong: this.onlyShowGuangDong === "all" ? false : true,
      };
      this.http.organization.getOrganizationTreeByArea(params).then((res) => {
        if (res.data.status === 200) {
          this.areaClassifyData = res.data.data;
          this.getTitleData(res.data.data);
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    editOrganization(record) {
      this.cleanOrganization();
      this.organizationFormValue = {
        id: record.id,
        name: record.name,
        address: record.address,
        areaIds: record.areaIds,
        contact: record.contact,
        introduction: record.introduction,
        isTopOrganization: record.isTopOrganization,
      };
      this.editOrganizationVisible = true;
        this.$refs.organizationRefFormEdit.resetFields()
    },
    addOrganization() {
      this.cleanOrganization();
      this.addOrganizationVisible = true;
        this.$refs.organizationRefFormAdd.resetFields()
    },
    addOrganizationChild(obj) {
      this.cleanOrganization();
      this.organizationFormValue.isTopOrganization = false;
      this.organizationFormValue.parent = obj.id;
      this.addOrganizationVisible = true;
        this.$refs.organizationRefFormAdd.resetFields()
    },
    cleanOrganization() {
      this.organizationFormValue = {
        id: "",
        name: "",
        address: "",
        areaIds: [],
        contact: "",
        introduction: "",
        parent: "",
        isTopOrganization: true,
      };
    },
    getOrganizationData() {
      // pagesize current 搜索的数据  旁边树的id
      let params = {
        areaId: this.treeSelectedKeys.toString(),
        name: this.query.name,
        address: this.query.address,
        contact: this.query.contact,
        introduction: this.query.introduction,
      };
      this.http.organization.getOrganizationList(params).then((res) => {
        if (res.data.status == 200) {
          this.spinning = false;
          this.data = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },

    toEditOrganization() {
      this.$refs.organizationRefFormEdit.validate((valid) => {
        if (valid) {
          this.http.organization.updateOrganization(this.organizationFormValue)
            .then((response) => {
              if (response.data.status === 200) {
                this.$message.success(response.data.msg);
                this.editOrganizationVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getOrganizationData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    removeAllQuery() {
      this.query = {
        coding: "",
        name: "",
        formerName: "",
        linkMan: "",
        phone: "",
        address: "",
      };
      this.searchQuery();
    },
    searchQuery() {
      this.spinning = true;
      this.getOrganizationData();
      this.drawerVisible = false;
    },
  },
};
</script>

<style scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>
